import {
  initialAddBathroomBlock, initialArchitecturalProjectBlock,
  initialCheckboxesBlock,
  initialCheckboxInputBlock,
  initialDeliveryBlock,
  initialEquipmentRentalBlock,
  initialFoundationRentalBlock,
  initialHomeInstallationBlock,
  initialInputsBlock,
  initialPorchBlock,
  initialRadioButtonsBlock, initialRadioCheckboxesBlock,
  initialStairsBlock,
  initialTerraceBlock,
  initialVerandaBlock
} from "./initialBlock";

export default function (blockData,  blocksFromState) {

  const blockType = blockData.values.type;

  switch (blockType) {
    case 'radio': return initialRadioButtonsBlock(blockData, blocksFromState);
    case 'checkboxes': return initialCheckboxesBlock(blockData, blocksFromState);
    case 'input': return initialInputsBlock(blockData, blocksFromState);
    case 'checkbox_input': return initialCheckboxInputBlock(blockData, blocksFromState);
    case 'add_bathroom': return initialAddBathroomBlock(blockData, blocksFromState);
    case 'veranda': return initialVerandaBlock(blockData, blocksFromState);
    case 'terrace': return initialTerraceBlock(blockData, blocksFromState);
    case 'porch': return initialPorchBlock(blockData, blocksFromState);
    case 'stairs': return initialStairsBlock(blockData, blocksFromState);
    case 'delivery': return initialDeliveryBlock(blockData, blocksFromState);
    case 'home_installation': return initialHomeInstallationBlock(blockData, blocksFromState);
    case 'equipment_rental': return initialEquipmentRentalBlock(blockData, blocksFromState);
    case 'foundation': return initialFoundationRentalBlock(blockData, blocksFromState);
    case 'radio_checkboxes': return initialRadioCheckboxesBlock(blockData, blocksFromState);
    case 'architectural_project': return initialArchitecturalProjectBlock(blockData, blocksFromState);

    default: throw new Error(`The block ${blockType} cannot be initialized`);
  }
}
